import { html, css } from 'lit-element';
import { KerikaCompositeDialog } from '../../components/kerika-composite-dialog.js';
import i18next from '@dw/i18next-esm';
import localize from '@dw/pwa-helpers/localize';

import typographyLiterals from '../../theme/typography-literals.js';

import '../../components/kerika-icon.js';
import '../../components/common/kerika-button.js';

/**
 * Shows a blocking dialog to inform user that new version of the application is available.
 */
class KerikaNewVersionBlockingNotificationDialog extends localize(i18next)(KerikaCompositeDialog) {
  constructor() {
    super();
    this.noCancelOnOutsideClick = true;
    this.noCancelOnEscKey = true;
    this.closeIconHide = true;
    this.nonCancellable = true;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host(:not([wide-layout])) #dialog-content {
          padding: 16px 20px;
        }

        :host([type="modal"]) #dialogContainer,
        :host([type="modal"]) .mdc-dialog {
          z-index: 99;
        }

        :host([wide-layout]) {
          --dw-dialog-max-width: 450px;
          --dw-dialog-min-width: 450px;
        }

        .header {
          justify-content: center;
          text-align: center;
        }

        :host([wide-layout]) .description {
          text-align: center;
        }

        .description {
          ${typographyLiterals.body1};
          text-align: center;
        }

        :host(:not([wide-layout])[type='modal'][placement='bottom']) #dialog-footer {
          padding: 0 16px 12px 16px;
          justify-content: center;
          --kerika-button-min-width: 100%;
        }

        .icon {
          display: flex;
          justify-content: center;
        }
      `,
    ];
  }

  static get properties() {
    return {
      /**
       * `true` When layout is wide.
       */
      wideLayout: { type: Boolean, reflect: true, attribute: 'wide-layout' },
    };
  }

  get _contentTemplate() {
    return html`
      <div class="icon"><kerika-icon .size=${56} .iconFont=${'OUTLINED'} .name=${'new_releases'}></kerika-icon></div>
      <p class="description">${i18next.t('app:newVersionBlockingDialog.description')}</p>
    `;
  }

  get _footerTemplate() {
    return html`
      <kerika-button filled @click=${this._onUpdate}>${i18next.t('app:newVersionBlockingDialog.buttons.update')}</kerika-button>
    `;
  }

 /**
  * Invoked When language is changed.
  * @override
  */
  _setLanguage(newLanguage) {
    super._setLanguage && super._setLanguage(newLanguage);
    this._setTitle();
  }
  
  _setTitle() {
    this.title = i18next.t('app:newVersionBlockingDialog.title');
  }

  _onUpdate() {
    this.dispatchEvent(new CustomEvent('confirm', { bubbles: false, composed: true }));
  }
}

window.customElements.define('kerika-new-version-blocking-notification-dialog', KerikaNewVersionBlockingNotificationDialog);
