import { html, css } from 'lit-element';
import { LitElement } from "@dreamworld/pwa-helpers/lit-element.js";
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { getFirestore, terminate } from "firebase/firestore";
import i18next from '@dw/i18next-esm';
import localize from '@dw/pwa-helpers/localize';

// These are the shared styles needed by this element.
import { sharedStyles } from '../../theme/shared-styles.js';
import '../kerika-ripple.js';
import './kerika-new-version-blocking-notification-dialog.js';

/**
 * It is a store connected component which reads data from redux store.
 * It displays message that application new version is available with action link to update application latest version.
 * Message is stiky at top center.
 *
 * USAGE PATTERN:
 *   <kerika-new-version-available-message></kerika-new-version-available-message>
 */

class KerikaNewVersionAvailableMessage extends localize(i18next)(LitElement) {
	static get styles() {
		return [
			sharedStyles,
			css`
				.new-version-available {
					position: fixed;
					left: 50%;
					top: 0;
					text-align: center;
					margin-left: -245px;
					z-index: 999;
					max-width: 490px;
					width: 100%;
					border: 1px solid var(--border-color);
					background-color: #FCF4E8;
					cursor: pointer;

					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
				}

				.new-version-available div {
					padding: 8px 24px;
					position: relative;
					overflow: hidden;
				}

				.link {
					color: var(--primary-color);
				}

				/* Mobile */
				@media all and (max-width: 471px){
					.new-version-available {
						left: 0;
						margin-left: 0;
						-webkit-border-radius: 0px;
						-moz-border-radius: 0px;
						border-radius: 0px;
					}
				}

		`
		];
	}

	constructor() {
		super();
		this._updateServiceWorkerVersion = this._updateServiceWorkerVersion.bind(this);
	}

	static get properties() {
		return {
			/**
			 * Type of message. It can be 'blocking' or 'non-blocking'.
			 */
			type: { type: String },

			/**
			 * Wide layout.
			 */
			wideLayout: { type: Boolean }
		};
	}

	render() {
		if(this.type === 'non-blocking') {
			return html `
				<section class="new-version-available">
					<div disable-user-selection @click=${this._updateServiceWorkerVersion}>
						${unsafeHTML(i18next.t('messages.newVersion'))}
						<kerika-ripple></kerika-ripple>
					</div>
				</section>
			`;
		}
		
		if(this.type === 'blocking') {
			return html`
				<kerika-new-version-blocking-notification-dialog
					.type=${'modal'}
					.placement=${this.wideLayout ? 'center': 'bottom'}
					.wideLayout=${this.wideLayout}
					.opened=${true}
					@confirm=${this._updateServiceWorkerVersion}>
				</kerika-new-version-blocking-notification-dialog>
			`;
		}

		return html``;
	}

	/**
	 * This promise will be resolved when user will confirm update.
	 * @returns {Promise}
	 */
	onConfirm() {
		this._confirm = new Promise((resolve) => {
      this.confirmResolve = resolve;
    });

    return this._confirm;
	}

	/**
	 * Upgrade new version using `DwServiceWorker` `upgrade` method.
	 * @protected
	 */
	async _updateServiceWorkerVersion() {
		const db = getFirestore();
		try {
			await terminate(db);
			console.log('Firestore connection terminated.');
		} catch (error) {
			console.error('Terminating Firestore is failed due to this: ', error);
		}
		this.confirmResolve && this.confirmResolve();
	}
}

window.customElements.define('kerika-new-version-available-message', KerikaNewVersionAvailableMessage);
